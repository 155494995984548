import {
  SyntheticEvent,
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  useCallback,
  useContext,
  useMemo
} from 'react';
import css from './index.module.scss';
import { Badge, Drawer, Button, IconNode, Tab, Tabs, Typography, ToolTip } from '@components/base';
import {
  LinkButton,
  ListPlaceholder,
  OrderCard,
  OrderCardListing,
  PaginationScroller,
  SearchBar,
  SearchPlaceholder,
  Seo
} from '@components/common';
import Filter, { FormType } from './components/filter';
import { CLIENT_ROUTES } from '@router/routes';
import Images from '@assets/images';
import { IFetchAllOrder, fetchAllOrder } from '@services/order.service';
import notify from '@helpers/toastify-helper';
import { OrderInfo } from '@helpers/types/order';
import debounce from 'lodash.debounce';
import {
  filterNonEmptyValues,
  getCommaSepratedValue,
  getPermissionFromLocalStorage,
  getValidEntriesLength,
  pushEventTracking
} from '@helpers/utils';
import AccessWrapper from '@authorization/access-wrapper';
import { USER_TYPES } from '@helpers/constants';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { ISelect } from '@helpers/types';
import RenderChildrenBasedOnScreen from 'src/hoc/screen-conditions';

interface OrderHomeStates {
  activeTab: number;
  orderList: OrderInfo[];
  isLoading: boolean;
  orderCount: number;
  hasNext: boolean;
  currentPageNumber: number;
  retry: boolean;
  searchText: string;
  isSearchLoading: boolean;
  state: string | null;
  showFilterDrawer: boolean;
  filters: FormType | null;
  filterData: any;
}

const STATE_MAPPING = [
  {
    value: 0,
    key: 'all',
    default: true
  },
  {
    value: 8,
    key: 'order_placed_state'
  },
  {
    value: 1,
    key: 'order_confirmed_state'
  },
  {
    value: 2,
    key: 'products_manufactured_state'
  },
  {
    value: 3,
    key: 'export_clearance_obtained_state'
  },
  {
    value: 4,
    key: 'shipment_departure_from_pol_state'
  },
  {
    value: 5,
    key: 'shipment_arrival_at_pod_state'
  },
  {
    value: 6,
    key: 'order_completed_state'
  },
  {
    value: 7,
    key: 'my_order'
  }
];

const getDefaultTab = () => {
  const defaultTab = STATE_MAPPING.find((tab) => tab.default);
  return defaultTab ? defaultTab : STATE_MAPPING[0];
};
const defaultTab = getDefaultTab();

const OrderHome = (props: any) => {
  const { actions } = props.modulePermissions;
  const debounceSearch = useRef<any>();
  const rootContainer = useRef<HTMLElement>(null);
  const topRef = useRef<HTMLElement>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [itemLimit, setItemLimit] = useState(10);
  const userType = useMemo(() => {
    return getPermissionFromLocalStorage()?.user_type;
  }, []);
  const initialPageNumber = parseInt(searchParams.get('page') || '1', 10);
  const activeTabParam = searchParams.get('activeTab');

  const initialOrderState = useMemo(() => {
    return getValueByKey(activeTabParam || defaultTab.key);
  }, [activeTabParam]);

  function getValueByKey(key: string) {
    const state = STATE_MAPPING.find((item) => item.key === key);
    return state ? state.value : defaultTab.value;
  }

  const [orderState, setOrderState] = useState<OrderHomeStates>({
    showFilterDrawer: false,
    activeTab: initialOrderState,
    orderList: [],
    isLoading: false,
    orderCount: 0,
    hasNext: false,
    currentPageNumber: initialPageNumber,
    retry: false,
    searchText: '',
    isSearchLoading: false,
    state: activeTabParam || defaultTab.key,
    filters: null,
    filterData: null
  });

  const {
    activeTab,
    orderList,
    orderCount,
    hasNext,
    currentPageNumber,
    retry,
    searchText,
    isLoading,
    isSearchLoading,
    state,
    filterData,
    filters
  } = orderState;

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('showSuccess') === 'true') {
      pushEventTracking('RFQ_SUCCESS');
      notify({
        message: 'Your RFQ has been placed successfully',
        severity: 'success'
      });
      navigate('/order');
    }
  }, [location.search]);

  const handleFilterClick = () => {
    setOrderState((prevState) => ({
      ...prevState,
      showFilterDrawer: !prevState.showFilterDrawer
    }));
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      navigate(0);
    });
  }, [activeTabParam]);

  const handleTabChange = async (_event: SyntheticEvent, newValue: number) => {
    const queryKey = STATE_MAPPING.find((state) => state.value === newValue);
    setOrderState((prevState) => ({
      ...prevState,
      activeTab: newValue,
      state: queryKey?.key ? queryKey.key : null,
      isLoading: true
    }));
    setSearchParams({
      page: '1',
      activeTab: queryKey?.key ?? defaultTab.key
    });
    if (queryKey?.key) {
      await getOrderList({
        page: 1,
        search_text: searchText,
        ...(queryKey.key !== 'all' && { order_state: queryKey.key }),
        ...filterData
      });
      setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    } else {
      setOrderState((prevState) => ({ ...prevState, isLoading: true }));
      await getOrderList({ page: 1, search_text: searchText, order_state: null, ...filterData });
      setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  const getOrderList = async (params: IFetchAllOrder) => {
    const response = await fetchAllOrder(params);
    if (response.success) {
      const { data } = response;
      setOrderState((prevState) => ({
        ...prevState,
        orderList: data.results,
        orderCount: data.count,
        hasNext: !!data.next,
        currentPageNumber: params.page ?? 0,
        retry: false,
        isSearchLoading: false,
        isLoading: false
      }));
    } else if (response.error) {
      setOrderState((prevState) => ({ ...prevState, retry: true, isSearchLoading: false }));
      notify({ message: response.error ?? 'Unable to fetch order list', severity: 'error' });
    }
  };

  const fetchSearchResult = async (searchText: string) => {
    await getOrderList({
      page: 1,
      search_text: searchText,
      ...(state !== 'all' && { order_state: state }),
      ...filterData
    });
  };

  const handleOrderSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setOrderState((prevState) => ({
      ...prevState,
      searchText: value.trim(),
      isSearchLoading: true
    }));
    debounceSearch.current?.cancel();
    debounceSearch.current = debounce(fetchSearchResult, 800);
    debounceSearch.current(value);
  };
  const date = new Date();
  const handleFilterApply = async (data: FormType) => {
    const trader = data.business_type_trader ? 'TR' : null;
    const manufacturer = data.business_type_manufacturer ? 'MR' : null;
    const indian = data.supplier_type_indian ? 'INDIAN' : null;
    const foreign = data.supplier_type_foreign ? 'FOREIGN' : null;
    const orderTypeMTT = data?.order_type?.value === 'MTT' ? 'MTT' : null;
    const orderTypeInd = data?.order_type?.value === 'IND' ? 'IND' : null;
    const cancelledOrders = data.isCancelled;
    const excludeCancelled = data.excludeCancelled;
    const completedOrders = data.isCompleted;
    const entityType = data.entity ? data.entity.entity_id : null;
    const orderStateDate = data.startOrderDate
      ? `${moment(data.startOrderDate).format('YYYY-MM-DD')}`
      : null;
    const orderEndDate = data.startOrderDate
      ? data.endOrderDate
        ? `${moment(data.endOrderDate).format('YYYY-MM-DD')}`
        : `${moment(date).format('YYYY-MM-DD')}`
      : null;
    const startBLAWBDate = data.startBLAWBDate
      ? `${moment(data.startBLAWBDate).format('YYYY-MM-DD')}`
      : null;
    const endBLAWBDate = data.startBLAWBDate
      ? data.endBLAWBDate
        ? `${moment(data.endBLAWBDate).format('YYYY-MM-DD')}`
        : `${moment(date).format('YYYY-MM-DD')}`
      : null;
    let business_type_list = '';
    if (trader) business_type_list = trader;
    if (manufacturer)
      business_type_list
        ? (business_type_list += `,${manufacturer}`)
        : (business_type_list = manufacturer);

    const incoterms_list =
      data.inco_terms_type &&
      data.inco_terms_type.map((incoterm: ISelect) => incoterm.value).join(',');
    let supplier_type_list = '';
    if (indian) supplier_type_list = indian;
    if (foreign)
      supplier_type_list ? (supplier_type_list += `,${foreign}`) : (supplier_type_list = foreign);
    let supply_type_list = '';
    if (orderTypeMTT) supply_type_list = orderTypeMTT;
    if (orderTypeInd)
      supply_type_list
        ? (supply_type_list += `,${orderTypeInd}`)
        : (supply_type_list = orderTypeInd);
    const reqBody = {
      supplier_id_list: data.supplier.map((item) => item.supplier_id).join(','),
      product_id_list: data.product.map((item) => item.product_id).join(','),
      customer_id_list: data.customer.map((customer) => customer.customer_id).join(','),
      users_list: data.selectedOrderPOC.map((user: any) => user.user_id).join(','),
      supply_type_list,
      incoterms_list,
      order_created_start_date: orderStateDate,
      order_created_end_date: orderEndDate,
      bl_or_awb_start_date: startBLAWBDate,
      bl_or_awb_end_date: endBLAWBDate,
      is_cancelled: cancelledOrders ? 1 : excludeCancelled ? 0 : null,
      entity_id: entityType,
      is_order_completed: completedOrders ? true : null
    };

    const params = filterNonEmptyValues(reqBody);

    setOrderState((prevState) => ({
      ...prevState,
      orderList: [],
      loading: true,
      filters: data,
      filterData: params
    }));
    setOrderState((prevState) => ({ ...prevState, isLoading: true }));
    await getOrderList({
      page: 1,
      ...(state !== 'all' && { order_state: state }),
      search_text: searchText,
      ...params
    });
    setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    handleFilterClick();
  };
  const handlePageChange = async (newPage: number) => {
    setOrderState((prev) => ({ ...prev, isLoading: true }));
    await getOrderList({
      page: newPage,
      search_text: searchText,
      ...(state !== 'all' && { order_state: state }),
      ...filterData
    });
    setOrderState((prevState) => ({ ...prevState, isLoading: false }));
    const queryKey = STATE_MAPPING.find((state) => state.value === initialOrderState);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      page: newPage.toString(),
      activeTab: queryKey?.key || defaultTab.key
    });
  };

  const validatePageChange = (event: any, value: number) => {
    if (isNaN(value)) return;
    if (value === 0 || value > Math.ceil(orderCount / itemLimit)) return;
    handlePageChange(value);
  };

  useEffect(() => {
    handlePageChange(currentPageNumber);
  }, []);

  const handleClearFilter = async () => {
    setOrderState((prevState) => ({
      ...prevState,
      filters: null,
      filterData: null,
      isLoading: true
    }));
    await getOrderList({
      page: 1,
      ...(state !== 'all' && { order_state: state }),
      search_text: searchText
    });
    setOrderState((prevState) => ({
      ...prevState,
      isLoading: false
    }));
  };

  return (
    <AccessWrapper show={actions?.read} showUnauthorised>
      <main ref={rootContainer} className={css.mainWrapper}>
        <Seo title="Orders" />
        <div className={css.header}>
          <div
            className={userType == USER_TYPES.internal ? css.textWrapper : css.textWrapperExternal}>
            <Typography variant="h2">Orders</Typography>
            <Badge>{orderCount}</Badge>
          </div>
          <AccessWrapper show={actions?.create}>
            <LinkButton
              variant="contained"
              to={`${CLIENT_ROUTES.addOrder}`}
              className={css.addOrderButton}>
              Add Order
            </LinkButton>
          </AccessWrapper>
        </div>
        <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
          <div className={css.tabsWrapper}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="All" value={0} />
              <Tab label="Order Placed" value={8} />
              <Tab label="Order Confirmed" value={1} />
              <Tab label="Products Manufactured" value={2} />
              <Tab label="Export Clearance Obtained" value={3} />
              <Tab label="Departure from POL" value={4} />
              <Tab label="Arrival at POD" value={5} />
              <Tab label="Completed" value={6} />
              <Tab label="My Orders" value={7} />
            </Tabs>
          </div>
        </AccessWrapper>

        <div className={css.filterWrapper}>
          <AccessWrapper show={actions?.read} showTo={[USER_TYPES.internal]} byPassShowWithShowTo>
            <div className={css.filterButtonWrapper}>
              <Button
                className={css.filterButton}
                variant="outlined"
                startIcon={<IconNode src={Images.filter} alt="filter icon" />}
                onClick={handleFilterClick}>
                {`Filter ${
                  getValidEntriesLength(filterData) > 0
                    ? `(${getValidEntriesLength(filterData)})`
                    : ''
                }`}
              </Button>
              {getValidEntriesLength(filterData) > 0 && (
                <Button
                  variant="text"
                  onClick={handleClearFilter}
                  startIcon={<IconNode src={Images.crossRed} alt="clear icon" />}>
                  Clear Filters
                </Button>
              )}
            </div>
          </AccessWrapper>

          <RenderChildrenBasedOnScreen showOnWeb>
            <RFQButton actions={actions} />
          </RenderChildrenBasedOnScreen>

          <div className={css.searchBar}>
            <SearchBar
              isLoading={isSearchLoading}
              placeholder="Search"
              className={css.search}
              onChange={handleOrderSearch}
            />
            <div>
              <ToolTip
                title={
                  userType == USER_TYPES.internal
                    ? `Search By: Customer Name, Supply Type, Port of Loading, Port of Discharge, Elchemy Entity Name, Supplier Name, Order ID, Product Name, PI Number, CI Number, PO Number, BL/AWB Number, Container Number`
                    : `Search By: Products, PI Number, PO Number`
                }
                placement="right">
                <div>
                  <IconNode src={Images.alertGrey} alt="eye icon" />
                </div>
              </ToolTip>
            </div>
          </div>

          <RenderChildrenBasedOnScreen showOnWeb>
            <PaginationNavigator
              currentPageNumber={currentPageNumber}
              orderCount={orderCount}
              itemLimit={itemLimit}
              validatePageChange={validatePageChange}
            />
          </RenderChildrenBasedOnScreen>
        </div>
        {/* Display RFQ button and pagination on mobile */}
        <RenderChildrenBasedOnScreen showOnMobile>
          <div className={css.searchDataWrapper}>
            <RFQButton actions={actions} />
            <PaginationNavigator
              currentPageNumber={currentPageNumber}
              orderCount={orderCount}
              itemLimit={itemLimit}
              validatePageChange={validatePageChange}
            />
          </div>
        </RenderChildrenBasedOnScreen>
        <section className={css.orderSection} ref={topRef}>
          {isLoading ? (
            <OrderCardListing />
          ) : orderList.length ? (
            orderList.map((order: any, index: number) => (
              <OrderCard
                //key={order.order_id}
                key={index}
                readableOrderId={order.readable_order_id}
                orderId={order.order_id}
                items={order.order_item}
                customerName={order.customer?.name ? order.customer?.name : '-'}
                createdBy={order.created_by}
                currentState={order.current_state}
                totalAmount={order.total ? getCommaSepratedValue(order.total) : '-'}
                orderInfo={order}
                actions={actions}
                pendingTasks={order?.pending_tasks?.length ? order.pending_tasks : []}
              />
            ))
          ) : searchText ? (
            <SearchPlaceholder searchText={searchText} />
          ) : (
            !isLoading && (
              <ListPlaceholder
                to={`${CLIENT_ROUTES.addOrder}`}
                title="No Order Added Yet"
                supportingText={
                  userType == USER_TYPES.internal
                    ? `When an order is added by you, they will show up here.`
                    : ``
                }
                buttonText={userType == USER_TYPES.internal ? `Add Order` : ``}
              />
            )
          )}
        </section>
        {!isLoading && (
          <PaginationScroller
            variant="text"
            defaultPage={1}
            count={orderCount}
            pageLimit={itemLimit}
            page={currentPageNumber}
            onChange={validatePageChange}
          />
        )}
        <Drawer open={orderState.showFilterDrawer} anchor="right" onClose={handleFilterClick}>
          <Filter value={filters} onApply={handleFilterApply} onClose={handleFilterClick} />
        </Drawer>
      </main>
    </AccessWrapper>
  );
};

export default OrderHome;

const RFQButton = (props: any) => {
  const { actions } = props;
  return (
    <AccessWrapper show={actions?.read} showTo={[USER_TYPES.customer]} byPassShowWithShowTo>
      <LinkButton
        variant="outlined-secondary"
        to="/enquiry?activestep=placeRFQ"
        eventTrackingName="RFQ_BTN_CLICK">
        Place RFQ
      </LinkButton>
    </AccessWrapper>
  );
};

const PaginationNavigator = (props: any) => {
  const { currentPageNumber, orderCount, itemLimit, validatePageChange } = props;
  return (
    <div className={css.paginationFilter}>
      <ToolTip title="Previous Page">
        <ArrowBackIosIcon
          sx={{
            height: '40px',
            cursor: currentPageNumber > 1 ? 'pointer' : 'default',
            color: currentPageNumber > 1 ? '#000000' : '#e2e2e2'
          }}
          onClick={() => validatePageChange(null, currentPageNumber - 1)}
        />
      </ToolTip>
      <Typography className={css.pageOfTotalPage}>
        {currentPageNumber} of {Math.ceil(orderCount / itemLimit)}
      </Typography>
      <ToolTip title="Next Page">
        <ArrowForwardIosIcon
          sx={{
            height: '40px',
            cursor: currentPageNumber < Math.ceil(orderCount / itemLimit) ? 'pointer' : 'default',
            color: currentPageNumber < Math.ceil(orderCount / itemLimit) ? '#000000' : '#e2e2e2'
          }}
          onClick={() => validatePageChange(null, currentPageNumber + 1)}
        />
      </ToolTip>
    </div>
  );
};
